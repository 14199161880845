<template>
  <div class="admin-menu-items">
    <div class="admin-menu-items-content">
      <LargeButton
        :label="$locale['saved']"
        :class="`${mkLink($links['my-saved-content'], 'on')}`"
        @click="goToNav({ view: _view }, $links['my-saved-content'])"
      />

      <LargeButton
        :label="$locale['bought']"
        :class="`${mkLink($links['my-bought-content'], 'on')}`"
        @click="goToNav({ view: _view }, $links['my-bought-content'])"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: ["_view"],
};
</script>
